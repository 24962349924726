"use strict";

//const baseURL="https://kmlindia-178419-ruby.b178419.dev.eastus.az.svc.builder.cafe";
const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";
//__MARKER_FOR_BACKEND_URL_REPLACEMENT

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
